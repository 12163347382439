import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class CreateClientFormStyle
{
  static containerSearchUserStyle(): CSSProperties
  {
    return {
      position: 'absolute',
      zIndex: 210,
      backgroundColor: CssVariableEnum['--color-white'],
      borderRadius: '8px',
      boxShadow: '0 2px 20px #00000029',
      width: '100%',
      padding: '5px 10px',
      top: 58,
      fontWeight: 500,
      border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
      transitionProperty: 'opacity, transform',
      transitionDuration: '270ms',
      transitionTimingFunction: 'ease'
    };
  }

  static headerSearchUserStyle(): CSSProperties
  {
    return  {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderBottom: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
      marginBottom: 8,
    };
  }

  static bodyUserTagStyle(): CSSProperties
  {
    return {
      marginLeft: '5px',
      fontSize: 12,
      borderRadius: '3px',
      border: `1px solid ${ CssVariableEnum['--color-grey-300'] }`,
      padding: '2px 4px'
    };
  }

  static cardSellsyStyle(): CSSProperties
  {
    return {
      zIndex: 200,
      backgroundColor: CssVariableEnum['--color-white'],
      borderRadius: '8px',
      width: '100%',
      padding: '5px 10px',
      top: 105,
      fontWeight: 500,
      border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
      transitionProperty: 'opacity, transform',
      transitionDuration: '270ms',
      transitionTimingFunction: 'ease'
    };
  }

  static selectCompanyStyle(): CSSProperties
  {
    return {
      backgroundColor: CssVariableEnum['--color-white'],
      borderRadius: '8px',
      boxShadow: '0 2px 20px #00000029',
      width: '100%',
      padding: '5px 10px',
      fontWeight: 500,
      border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
      transitionProperty: 'opacity, transform',
      transitionDuration: '270ms',
      transitionTimingFunction: 'ease',
      marginBottom: '10px'
    };
  }

  static resultsContainerStyle(): CSSProperties {
    return {
      marginTop: 5,
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'rgba(228, 231, 236)',
      borderRadius: '8px',
      boxShadow: '0 2px 20px #00000029',
      width: '840px',
      padding: '10px',
      border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
      height: '240px',
      overflowY: 'scroll',
      overflowX: 'hidden',
    };
  }
}