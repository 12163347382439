import React, { ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { CompanySellsyInterface } from '@/Modules/Client/Interface/CompanySellsyInterface';
import { dateFormat } from '@/Utils/DateUtils';
import { BlockBillingAddressSellsyStyle } from '@/Modules/LegalNotice/Style/BlockBillingAddressSellsyStyle';
import LegalNoticeFomCardComponent from '@/Modules/LegalNotice/Components/Form/Admin/FormCardComponent';
import { LuRepeat } from 'react-icons/lu';
import Swal from 'sweetalert2';

interface ComponentProps
{
  isDisplayBlock: boolean,
  authCompanyContext: AuthCompanyContextType,
  selectedClient: ClientInterface|null,
  consumer: ConsumerDataInterface|null,
  formData: AddressInterface|null,
  formDataFormSelly: (addressesData: AddressInterface) => void,
  sendExtSellsyIdForConsumer: (clientSellsyId: number) => void,
  sendExtSellsyIdForAddress: (addressSellsyId: number) => void
}

interface ComponentState
{
  formData: AddressInterface|null,
  sellsy: {
    searchTerm: string|null,
    searchClientResults: any[],
    clientBillingAddress: any|null,
    selectedClient: CompanySellsyInterface|null,
    hasSearched: boolean,
    isCreated: boolean,
    isNotFound: boolean,
    isLoadingSellsySearch: boolean
  }
}

export default class BlockSearchSellsyClientComponent extends React.Component<ComponentProps, ComponentState>
{
  apiClientSellsyService: ApiAdminSellsyService;
  companySellsyDropDownRef: React.RefObject<HTMLDivElement> | null;

  constructor(props: any) {
    super(props);

    // State
    this.state = this.initState();

    // Service
    this.apiClientSellsyService = new ApiAdminSellsyService();

    // Ref
    this.companySellsyDropDownRef =  React.createRef();

    // Bind
    this.prepareComponentForEdit = this.prepareComponentForEdit.bind(this);
    this.searchFormCompanySellsyRender = this.searchFormCompanySellsyRender.bind(this);
    this.resultCompanyListSellsyRender = this.resultCompanyListSellsyRender.bind(this);
    this.resultNotFountCompanySellsyRender = this.resultNotFountCompanySellsyRender.bind(this);
    this.companySellsyRender = this.companySellsyRender.bind(this);
    this.setCompanyAddresses = this.setCompanyAddresses.bind(this);
    this.onCompanySellsySelect = this.onCompanySellsySelect.bind(this);
  }

  render(): ReactElement
  {
    return (
      <>
        { this.props.isDisplayBlock &&
          <>
            <LegalNoticeFomCardComponent
              title={"Lier le client final à Sellsy"}
              children={
                <>
                  <>
                    { this.searchFormCompanySellsyRender() }
                    { this.resultCompanyListSellsyRender() }
                    { this.resultNotFountCompanySellsyRender() }
                    { this.selectedCompanyRender() }
                  </>
                </>
              }
            />
          </>
        }
      </>
    );
  }

  //<editor-fold desc="Render methods" defaultstate="collapsed">

  private searchFormCompanySellsyRender(): ReactElement
  {
    return (
      <>
        <div style={ {
          position: 'relative',
          backgroundColor: CssVariableEnum['--color-purple-100'],
          borderRadius: 15,
          padding: 10,
          marginTop: 10,
          border: `1px solid ${ CssVariableEnum['--color-purple-300'] }`
        } }>
          <div style={ {
            ...FontStyle.h3(CssVariableEnum['--color-purple-500']),
            marginBottom: 10
          } }>
            Rechercher ce client sur Sellsy
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-end'}}>
            <Input
              type="text"
              style={ { display: 'flex' } }
              label="Rechercher un client sur Sellsy, par son nom"
              name="name"
              value={ this.state.sellsy.searchTerm || '' }
              onChange={ (event: any) => this.onChangeSearchSellsy(event) }
            />

            { this.state.sellsy.selectedClient &&
              <Button
                type={ 'default-dark' }
                onClick={ (event: any) => this.cancelSelectedClient(event) }
              >
                Annuler la recherche
              </Button>
            }

            <Button
              style={ { marginTop: '22px', minWidth: '90px', position: 'relative' } }
              type="default-purple"
              onClick={ () => this.searchCompanySellsyByTerm() }
              disabled={ this.state.sellsy.isLoadingSellsySearch }
            >
              Rechercher
            </Button>
          </div>
        </div>
      </>
    );
  }

  private resultCompanyListSellsyRender(): ReactElement
  {
    return (
      <>
        { this.state.sellsy.searchClientResults.length !== 0 &&
          <div style={ BlockBillingAddressSellsyStyle.resultsContainerStyle() }>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <b style={{ marginBottom: '5px' }}>Nombre de résultat: { this.state.sellsy.searchClientResults.length }</b>
            </div>
            { this.state.sellsy.searchClientResults.map((companySellsy: CompanySellsyInterface, index: number) => (
              <React.Fragment key={ index }>
                <div
                  ref={ this.companySellsyDropDownRef }
                  style={ BlockBillingAddressSellsyStyle.selectCompanyStyle() }
                >
                  { this.companySellsyRender(companySellsy) }
                </div>
              </React.Fragment>
            )) }
          </div>
        }
      </>
    );
  }

  private resultNotFountCompanySellsyRender(): ReactElement
  {
    return (
      <>
        { this.state.sellsy.hasSearched && this.state.sellsy.isNotFound &&
          <>
            <div
              ref={ this.companySellsyDropDownRef }
              style={ BlockBillingAddressSellsyStyle.notFoundResultsContainerStyle() }
            >
              Aucun client trouvé sur Sellsy, <br />Merci de bien vérifier votre orthographe sur le nom du client !
              <Button
                type={ 'inline-default-blue' }
                style={ { marginTop: '22px', minWidth: '205px', position: 'relative' } }
                onClick={ (event: any) => this.createNewCompanySellsy(event) }
              >
                Créer un nouveau client Sellsy
              </Button>
            </div>
          </>
        }
      </>
    );
  }

  private companySellsyRender(companySellsy: CompanySellsyInterface): ReactElement
  {
    // Duplicate here, is wanted, even if is the same, we keep it !
    return (
      <>
        <div
          key={ (companySellsy.object.id) ? companySellsy.object.id : companySellsy.id }
          style={ { cursor: 'pointer', padding: '5px 10px' } }
          onClick={ (event: any) => this.onCompanySellsySelect(event, companySellsy) }
        >
          <div style={ { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' } }>
            <div style={ { fontWeight: 600, fontSize: '14px' } }>
              { companySellsy.name }
            </div>
          </div>
          <div style={ { marginBottom: '5px' } }>
            <span style={ { fontSize: '12px', fontWeight: 400 } }>ID: </span>
            <span style={ { fontSize: '12px' } }>{ (companySellsy.object.id) ? companySellsy.object.id : companySellsy.id }</span>
          </div>
          <div style={ { marginBottom: '5px' } }>
            <span style={ { fontSize: '12px', fontWeight: 400 } }>Créé le: </span>
            <span style={ { fontSize: '12px' } }>{ dateFormat(companySellsy.created) }</span>
          </div>
          <div style={ { fontSize: '12px', color: CssVariableEnum['--color-grey-400'] } }>
            { companySellsy.email }
          </div>
        </div>
      </>
    );
  }

  private selectedCompanyRender(): ReactElement
  {
    if (this.state.sellsy.isLoadingSellsySearch) {
      return (
        <div style={ { marginTop: 20 } }>
          <LoaderComponent/>
        </div>
      );
    }

    if (this.state.sellsy.isCreated) {
      return (
        this.sellsyAddNewCompanyRender()
      );
    }

    const companySellsy = this.state.sellsy.selectedClient;

    return (
      <>
        { this.state.sellsy.selectedClient &&
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
              {/* RENDER SELECTED CLIENT */}
              { this.companyRender(companySellsy as CompanySellsyInterface) }
              {/* RENDER SELECTED CLIENT ADDRESSES */}
              { this.addressesRender(companySellsy as CompanySellsyInterface) }
            </div>
          </>
        }
      </>
    );
  }

  private companyRender(companySellsy: CompanySellsyInterface): ReactElement
  {
    return (
      <>
        <div style={ BlockBillingAddressSellsyStyle.selectedClientStyle() }>
          <div style={{ minHeight: 110 }}>
            <div style={ { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' } }>
              <div style={ { fontWeight: 600, fontSize: '14px' } }>
                { companySellsy.name }
              </div>
            </div>
            <div style={ { marginBottom: '5px' } }>
              <span style={ { fontSize: '12px', fontWeight: 400 } }>ID: </span>
              <span style={ { fontSize: '12px' } }>{ companySellsy.id }</span>
            </div>
            <div style={ { marginBottom: '5px' } }>
              <span style={ { fontSize: '12px', fontWeight: 400 } }>Créé le: </span>
              <span style={ { fontSize: '12px' } }>{ dateFormat(companySellsy.created) }</span>
            </div>
            <div style={ { fontSize: '14px', color: CssVariableEnum['--color-grey-400'] } }>
              { companySellsy.email }
            </div>
          </div>

          <div style={ { display: 'flex', justifyContent: 'end' } }>
            <Button
              style={ { marginTop: 10 } }
              type={ 'inline-default-blue' }
              iconLeft={ <LuRepeat/> }
              onClick={ () => this.onUpdateConsumerName() }
            >
              Mettre a jour
            </Button>
          </div>
        </div>
      </>
    );
  }

  private addressesRender(companySellsy: CompanySellsyInterface): ReactElement
  {
    return (
      <>
        <div style={ BlockBillingAddressSellsyStyle.selectedClientStyle() }>
          { companySellsy&&this.state.sellsy.clientBillingAddress&&
            <>
              <div style={ { marginBottom: '5px' } }>
                <div style={ { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' } }>
                  <div style={ { fontWeight: 600, fontSize: '14px' } }>
                    { this.state.sellsy.clientBillingAddress.name.toUpperCase() }
                  </div>

                  <div style={ { fontWeight: 500, fontSize: '14px', color: CssVariableEnum['--color-grey-500'] } }>
                    #{ this.state.sellsy.clientBillingAddress.id }
                  </div>
                </div>

                <div style={ { marginBottom: '5px' } }>
                  <span style={ { fontSize: '12px', fontWeight: 400 } }>Rue : </span>
                  <span style={ { fontSize: '12px' } }>{ this.state.sellsy.clientBillingAddress.address_line_1 }</span>
                </div>
                <div style={ { marginBottom: '5px' } }>
                  <span style={ { fontSize: '12px', fontWeight: 400 } }>Code postal : </span>
                  <span style={ { fontSize: '12px' } }>{ this.state.sellsy.clientBillingAddress.postal_code }</span>
                </div>
                <div style={ { marginBottom: '5px' } }>
                  <span style={ { fontSize: '12px', fontWeight: 400 } }>Ville : </span>
                  <span style={ { fontSize: '12px' } }>{ this.state.sellsy.clientBillingAddress.city }</span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    style={{ marginTop: 10 }}
                    type={ 'inline-default-blue' }
                    iconLeft={ <LuRepeat/> }
                    onClick={ () => this.onUpdateAddress() }
                  >
                    Mettre a jour
                  </Button>
                </div>
              </div>
            </>
          }
        </div>
      </>
    )
  }

  private sellsyAddNewCompanyRender(): ReactElement
  {
    return (
      <>
        <div style={ {
          backgroundColor: CssVariableEnum['--color-purple-50'],
          border: `1px solid ${ CssVariableEnum['--color-purple-500'] }`,
          borderRadius: '6px',
          marginTop: '10px',
          padding: '5px 10px'
        } }>
          <div style={ { display: 'flex' } }>
            <img
              style={ {
                width: '50px',
                marginRight: '15px',
                padding: '5px',
                borderRadius: '5px',
                backgroundColor: CssVariableEnum['--color-purple-200']
              } }
              src="/img/logo-sellsy.png" alt="Sellsy Logo"
            />

            <div style={ { color: CssVariableEnum['--color-purple-500'] } }>
              Le client sera créé automatiquement pendant la création de l'annonce légale,
              <br/>
              avec les informations de l'encart Société
            </div>
          </div>

          <Button
            type={ 'danger' }
            style={ { display: 'flex', justifyContent: 'end', minWidth: '205px', position: 'relative' } }
            onClick={ (event: any) => this.cancelCreatedSellsy(event) }
            disabled={ this.state.sellsy.isLoadingSellsySearch }
          >
            Annuler
          </Button>
        </div>
      </>
    );
  }

  //</editor-fold>

  //<editor-fold desc="State methods" defaultstate="collapsed">

  async componentDidMount(): Promise<void>
  {
    if (this.props.formData) {
      this.setState({ formData: this.props.formData });

      this.prepareComponentForEdit();
    }
  }

  private initState(): ComponentState
  {
    return {
      formData: this.props.formData||{
        name: null,
        street: '',
        number: null,
        additionalData: '',
        zipCode: '',
        city: '',
        country: '',
        phone: null,
        isBillingAddress: true
      },
      sellsy: {
        searchTerm: null,
        searchClientResults: [],
        clientBillingAddress: null,
        selectedClient: null,
        hasSearched: false,
        isNotFound: false,
        isCreated: false,
        isLoadingSellsySearch: false
      }
    };
  }

  private async prepareComponentForEdit(): Promise<void>
  {
    if (this.props.consumer?.extSellsyId) {
      // Set is Loading
      this.setSellsyData({ isLoadingSellsySearch: true });

      const getSellsyClient = await this.apiClientSellsyService.getCompany(
        this.props.authCompanyContext.authCompany.id,
        this.props.consumer?.extSellsyId
      );

      const getAddresses = await this.apiClientSellsyService.getCompanyAddressList(
        this.props.authCompanyContext.authCompany.id,
        this.props.consumer?.extSellsyId
      );

      if (getSellsyClient && getAddresses) {
        this.setSellsyData({
          selectedClient: getSellsyClient,
          clientBillingAddress: getAddresses.data[0],
          hasSearched: false,
          isCreated: false,
          isLoadingSellsySearch: false
        }, () => {
          this.preparePropsFormData(getAddresses.data[0], getSellsyClient.id);
        });
      }
    }
  }

  private setSellsyData(update: Partial<ComponentState['sellsy']>, callback?: () => void): void
  {
    this.setState((prevState: any) => ({
      sellsy: {
        ...prevState.sellsy,
        ...update
      }
    }), callback);
  }

  //</editor-fold>

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private onChangeSearchSellsy(event: any): void
  {
    if (event.target.value === '') {
      this.setSellsyData({ hasSearched: false, searchClientResults: [] });
    }
    this.setSellsyData({ searchTerm: event.target.value });
  }

  private async searchCompanySellsyByTerm(): Promise<void>
  {
    this.setSellsyData({ isLoadingSellsySearch: true });

    if (this.state.sellsy.searchTerm) {
      const searchCompanies = await this.apiClientSellsyService.searchCompanyByTerm(
        this.props.authCompanyContext.authCompany.id,
        this.state.sellsy.searchTerm
      );

      this.setSellsyData(
        {
          searchClientResults: searchCompanies,
          isNotFound: (searchCompanies.length === 0),
          isLoadingSellsySearch: false,
          hasSearched: true,
          clientBillingAddress: null
        }
      );
    }
  }

  private async onCompanySellsySelect(event: any, companySellsy: CompanySellsyInterface): Promise<void>
  {
    event.preventDefault();
    event.stopPropagation();

    const updatedClient = {
      ...companySellsy,
      id: (companySellsy.object.id) ? companySellsy.object.id : companySellsy.id
    };

    this.setSellsyData(
      {
        selectedClient: updatedClient,
        searchClientResults: [],
        searchTerm: null,
        hasSearched: false,
        isLoadingSellsySearch: false
      },
      async () => {
        await this.setCompanyAddresses(companySellsy);
      }
    );
  }

  private async setCompanyAddresses(companySellsy: CompanySellsyInterface): Promise<void>
  {
    // Call list addresses
    this.setSellsyData({ clientBillingAddress: companySellsy.delivery_address });

    // Prepare formData
    if (companySellsy.delivery_address) {
      this.preparePropsFormData(companySellsy.delivery_address, companySellsy.object.id);
    }
  }

  private cancelSelectedClient(event: any): void
  {
    event.preventDefault();
    event.stopPropagation();

    this.setSellsyData({
      selectedClient: null,
      searchTerm: null,
      hasSearched: false,
      searchClientResults: []
    });
  }

  private cancelCreatedSellsy(event: any): void
  {
    event.preventDefault();
    event.stopPropagation();

    this.setSellsyData({
      selectedClient: null,
      searchTerm: null,
      hasSearched: false,
      isCreated: false,
      searchClientResults: []
    });
  }

  private async createNewCompanySellsy(event: any): Promise<void>
  {
    event.preventDefault();
    event.stopPropagation();

    this.setSellsyData({ isLoadingSellsySearch: true, isNotFound: false });

    const newSellsyClientResponse = await this.apiClientSellsyService.createCompanyFromLegalNotice(
      this.props.authCompanyContext.authCompany.id,
      {
        name: this.props.consumer?.name,
        siren: (this.props.consumer?.siren !== '') ? this.props.consumer?.siren : null,
        address: {
          number: this.props.consumer?.address?.number,
          street: this.props.consumer?.address?.street,
          additionalData: this.props.consumer?.address?.additionalData,
          zipCode: this.props.consumer?.address?.zipCode,
          city: this.props.consumer?.address?.city,
          country: 'France',
        }
      }
    );

    if (newSellsyClientResponse) {
      const getSellsyClient = await this.apiClientSellsyService.getCompany(
        this.props.authCompanyContext.authCompany.id,
        newSellsyClientResponse.clientSellsyId
      );

      const getAddresses = await this.apiClientSellsyService.getCompanyAddressList(
        this.props.authCompanyContext.authCompany.id,
        newSellsyClientResponse.clientSellsyId
      );

      this.setSellsyData({
        selectedClient: getSellsyClient,
        clientBillingAddress: getAddresses.data[0],
        hasSearched: false,
        isLoadingSellsySearch: false
      });

      this.preparePropsFormData(getAddresses.data[0], getSellsyClient.id);
    }
  }

  private preparePropsFormData(addressData: any, clientSellsyId: number): void
  {
    const address = addressData.address_line_1;
    const addressParts = address.match(/^(\d+)\s+(.*)$/);

    const prepareAddressData = {
      name: addressData.name,
      number: addressParts ? parseInt(addressParts[1]) : null,
      street: addressParts ? addressParts[2].trimStart() : address,
      additionalData: addressData.address_line_2,
      zipCode: addressData.postal_code,
      city: addressData.city,
      country: addressData.country,
      phone: addressData.phone,
      extSellsyId: addressData.id,
      isBillingAddress: true
    };

    this.props.formDataFormSelly(prepareAddressData);
    this.props.sendExtSellsyIdForConsumer(clientSellsyId);
    this.props.sendExtSellsyIdForAddress(addressData.id);
  }

  private async onUpdateConsumerName(): Promise<void> {
    const result = await Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: 'Vous êtes sur le point de mettre à jour le nom du client final sur Sellsy.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, mettre à jour',
      cancelButtonText: 'Annuler'
    });

    if (result.isConfirmed) {
      if (this.props.selectedClient && this.props.consumer) {
        await this.apiClientSellsyService.updateCompanyName(
          this.props.authCompanyContext.authCompany.id,
          this.props.consumer?.extSellsyId as number,
          this.props.consumer?.name as string
        );

        // Reload Component
        await this.prepareComponentForEdit();

        Swal.fire('Mis à jour !', 'Le nom du client final a été mis à jour.', 'success');
      }
    }
  }

  private async onUpdateAddress(): Promise<void> {
    const result = await Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: "Vous êtes sur le point de mettre à jour l'adresse du client final sur Sellsy.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, mettre à jour',
      cancelButtonText: 'Annuler'
    });

    if (result.isConfirmed) {
      if (this.props.selectedClient && this.props.consumer) {
        await this.apiClientSellsyService.updateCompanyAddress(
          this.props.authCompanyContext.authCompany.id,
          this.props.consumer?.extSellsyId as number,
          this.props.consumer?.address?.extSellsyId as number,
          { address: this.props.consumer?.address }
        );

        // Reload Component
        await this.prepareComponentForEdit();

        Swal.fire('Mis à jour !', "L'adresse du client final a été mise à jour.", 'success');
      }
    }
  }

  //</editor-fold>
}