import React, { ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { SelectMultiple } from '@/Modules/App/Components/Atom/Form/SelectMultiple';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

interface ComponentProps
{
  selectedClient: ClientInterface | null,
  sendTo: {
    [key: string]: string[];
  },
  onSelections: (selections: SendToSelections) => void,
  fieldsToShow?: string[]
}

interface ComponentState
{
  selections: SendToSelections;
}

export interface SendToSelections
{
  [key: string]: string[] | string | undefined;
  invoice?: string[];
  certificate?: string[];
  receipt?: string[];
  credit?: string[];
}

export default class LegalNoticeSendToFormComponent extends React.Component<ComponentProps, ComponentState>
{
  state = {
    selections: {} as SendToSelections,
  };

  constructor(props: any) {
    super(props);

    // Bind
    this.onChangeSelectCollaborator = this.onChangeSelectCollaborator.bind(this);
    this.initSelectionsProps = this.initSelectionsProps.bind(this);
  }

  componentDidMount(): void
  {
    if (this.props.selectedClient?.options && this.props.selectedClient?.options?.sendTo) {
      this.initSelectionsProps();
    }
  }

  componentDidUpdate(prevProps: Readonly<{ selectedClient: ClientInterface | null }>, prevState: Readonly<{ selections: SendToSelections }>, snapshot?: any) {
    if (this.isAllSelected() && prevState.selections !== this.state.selections) {
      this.props.onSelections(this.state.selections);
    }
  }

  render(): ReactElement
  {
    const fieldsToShow = this.props.fieldsToShow || ['invoice', 'certificate', 'certificate-web', 'receipt', 'credit'];

    return (
      <>
        { fieldsToShow.includes('invoice') && this.buildBillingStepContent('Facture / Devis', 'invoice') }
        { fieldsToShow.includes('certificate') && this.buildBillingStepContent('Attestation', 'certificate') }
        { fieldsToShow.includes('certificate-web') && this.buildBillingStepContent('Certificat numérique', 'certificate-web') }
        { fieldsToShow.includes('receipt') && this.buildBillingStepContent('Justificatif', 'receipt') }
        { fieldsToShow.includes('credit') && this.buildBillingStepContent('Avoir', 'credit') }
      </>
    );
  }

  private buildBillingStepContent(title: string, billingStep: string): ReactElement
  {
    return (
      <>
        <div className="d-flex justify-content-between mt-4">
          <span style={ {
            marginBottom: '2px',
            fontWeight: 600,
            color: CssVariableEnum['--color-grey-900']
          } }>{ title }:</span>
          <SelectMultiple
            options={this.props.selectedClient?.collaborators || []}
            initialTags={ this.props.sendTo[billingStep] }
            titleInputLabel="Choisir un  ou plusieurs collaborateur(s)"
            titleSuggestOption="Collaborateurs suggérés"
            onChange={(selectedMails: string[]) => this.onChangeSelectCollaborators(selectedMails, billingStep)}
            renderOptionLabel={ (option: CollaboratorInterface) => option.email }
          />
        </div>
      </>
    );
  }

  private onChangeSelectCollaborator(collaborator: CollaboratorInterface, billingStep: string): void
  {
    this.setState(prevState => {
      const newSelections: SendToSelections = { ...prevState.selections };

      let currentSelections = newSelections[billingStep] as string[] | undefined;
      if (!currentSelections) {
        currentSelections = [];
        newSelections[billingStep] = currentSelections;
      }

      if (!currentSelections.includes(collaborator.email)) {
        currentSelections.push(collaborator.email);
      }

      return { selections: newSelections };
    });
  }

  private onChangeSelectCollaborators(selectedMails: string[], billingStep: string): void
  {
    this.setState(prevState => ({
      selections: {
        ...prevState.selections,
        [billingStep]: selectedMails
      }
    }), () => {
      this.props.onSelections(this.state.selections);
    });
  }

  private isAllSelected(): boolean
  {
    const requiredSteps: string[] = ['invoice', 'certificate', 'receipt', 'credit'];
    return requiredSteps.every(billingStep => {
      const selection = this.state.selections[billingStep];
      return Array.isArray(selection) && selection.length > 0;
    });
  }

  private initSelectionsProps(): void
  {
    this.setState({ selections: this.props.sendTo });
  }
}