import LocalStorageService from '@/Service/Common/LocalStorageService';
import { fetchWrapper } from '@/Loader/FetchWrapper';

export default class BaseService
{
  protected static API_URL: any = process.env.REACT_APP_API_URL;

  protected buildHeaders(): HeadersInit
  {
    return {
      'Authorization': 'Bearer ' + LocalStorageService.getToken(),
      'Content-Type': 'application/json',
    };
  }

  protected buildPublicHeaders(): HeadersInit
  {
    return {
      'Content-Type': 'application/json',
    };
  }

  protected buildMultipartHeaders(): HeadersInit {
    return {
      'Authorization': 'Bearer ' + LocalStorageService.getToken()
    };
  }

  protected buildXmlHeaders(): HeadersInit
  {
    return {
      'Authorization': 'Bearer ' + LocalStorageService.getToken(),
      'Accept': 'application/xml'
    };
  }

  protected async fetch(url: string, options: RequestInit): Promise<any>
  {
    return fetchWrapper(url, options);
  }
}