import React, { CSSProperties, ReactElement } from 'react';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';
import { ClientTypeEnum } from '@/Modules/Client/Enum/ClientTypeEnum';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { ClientDiscountPreferenceEnum } from '@/Modules/Client/Enum/ClientDiscountPreferenceEnum';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import LineSeparator from '@/Modules/App/Components/Atom/Separator/LineSeparator';
import Textarea from '@/Modules/App/Components/Atom/Form/Textarea';
import LegalNoticeSendToFormComponent from '@/Modules/LegalNotice/Components/Form/LegalNoticeSendToFormComponent';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import PaymentMethodEnum from '@/Modules/Payment/Enum/PaymentMethodEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { LuRepeat } from 'react-icons/lu';

interface ComponentProps
{
	client: ClientInterface,
	departments: DepartmentInterface[],
	onUpdateOptions: (formData: any, clientId: number) => void
}

interface ComponentState
{
	isLoadingComponent: boolean,
	newspapers: NewspaperInterface[] | null,
	isOptionsUpdated: boolean
	formData: {
		options: {
			newspaperType: NewspaperTypeEnum | string | null,
			newspaper: NewspaperInterface | string | null,
			department: DepartmentInterface | string | null,
			paymentMethod: PaymentMethodEnum | string | null,
			billedTo: BilledToTypeEnum | string | null,
			type: ClientTypeEnum | null,
			discountGranted: number,
			discountPreference: any | null,
			commentary: string | null,
			numberOfCopies: number,
			sendTo: any | null
		}
	}
}

export default class ClientOptionsComponent extends React.Component
	<ComponentProps, ComponentState>
{
	publicService: ApiPublicService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.publicService = new ApiPublicService();

		// Bind
		this.initOptionsData = this.initOptionsData.bind(this);
		this.onUpdateOptions = this.onUpdateOptions.bind(this);
		this.onSendToSelected = this.onSendToSelected.bind(this);
	}

	render(): ReactElement
	{
		if (this.state.isLoadingComponent) {
			return (
				<LoaderComponent/>
			);
		}

		return (
			<>
				{ this.optionFormContent() }
				{ this.state.isOptionsUpdated &&
          <div style={ {
						position: 'fixed',
						bottom: 20,
						right: 30,
						height: 50,
						display: 'inline-flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						zIndex: 1000,
					} }>
            <Button
              label={ 'Mettre à jour' }
              iconLeft={ <LuRepeat/> }
              type={ 'default-blue' }
              onClick={ () => this.onUpdateOptions() }
            >
            </Button>
          </div>
				}
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		this.initOptionsData();
	}

	componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState): void
	{
		if (prevState.formData.options !== this.state.formData.options) {
			this.setState({ isOptionsUpdated: true });
		}
	}

	private initState(): ComponentState
	{
		return {
			isLoadingComponent: true,
			isOptionsUpdated: false,
			newspapers: [] as NewspaperInterface[],
			formData: {
				options: {
					newspaperType: null as NewspaperTypeEnum | string | null,
					newspaper: null as NewspaperInterface | string | null,
					department: null as DepartmentInterface | string | null,
					paymentMethod: null as PaymentMethodEnum | string | null,
					billedTo: null as BilledToTypeEnum | string | null,
					type: null as ClientTypeEnum | null,
					discountGranted: 0,
					discountPreference: null,
					numberOfCopies: 0,
					commentary: null,
					sendTo: null
				},
			}
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private optionFormContent(): ReactElement
	{
		return (
			<>
				<div style={ { marginTop: '30px', display: 'flex', flexDirection: 'column', height: '100%' } }>
					<div style={ { backgroundColor: CssVariableEnum['--color-white'], padding: 10, borderRadius: 15 } }>
						<Title type={ 'h6' }>Préférences</Title>
						{ this.preferenceRowContent(
							'Type de client',
							'Choisissez le type de client afin de mieux les catégoriser dans vos rapports.',
							'client-type',
							{ position: 'relative' },
							<SelectComponent
								key={ 'client-type' }
								containerDivWidth={ '200px' }
								buttonWidth={ 180 }
								selectMenuWidth={ 200 }
								listOptions={ ClientTypeEnum.options }
								onSelectedOption={ (event: any) => this.onChangeSelectClientType(event) }
								selectedValue={ (this.state.formData.options.type) ? ClientTypeEnum.findByValue(this.state.formData.options.type) : null }
								renderOptionLabel={ (option) => `${ option.label }` }
							/>
						) }

						{ this.preferenceRowContent(
							'Facturé à',
							'Choix de la facturation au donneur d\'ordre ou au client',
							'billing-type',
							{ marginTop: '14px' },
							<SelectComponent
								key={ 'billing-type' }
								buttonWidth={ 200 }
								listOptions={ BilledToTypeEnum.options }
								onSelectedOption={ (event: any) => this.onChangeSelectBilledTo(event) }
								selectedValue={ (this.state.formData.options.billedTo) ? BilledToTypeEnum.findByValue(this.state.formData.options.billedTo as string) : null }
								renderOptionLabel={ (option) => `${ option.label }` }
							/>
						) }

						{ this.preferenceRowContent(
							'Type de publication',
							'Attribuez une préférence du type des publications',
							'newspaper-type',
							{ marginTop: '14px' },
							<SelectComponent
								key={ 'newspaper-type' }
								buttonWidth={ 180 }
								listOptions={ NewspaperTypeEnum.options }
								onSelectedOption={ (event: any) => this.onChangeSelectedNewspaperType(event) }
								selectedValue={
									(this.state.formData.options?.newspaperType)
										? NewspaperTypeEnum.findByValue(this.state.formData.options.newspaperType as string)
										: null
								}
								renderOptionLabel={ (option) => `${ option.label }` }
							/>
						) }

						{ this.preferenceRowContent(
							'Département',
							'Choisissez le département de publication de préférence',
							'department-select',
							{ marginTop: '14px' },
							<SelectComponent
								key={ 'department-select' }
								buttonWidth={ 250 }
								style={ { marginTop: '10px' } }
								selectionText={ 'Choisir un département' }
								listOptions={ this.props.departments }
								selectedValue={ (this.state.formData.options?.department) ? this.state.formData.options.department as DepartmentInterface : null }
								onSelectedOption={ (event: any) => this.onSelectedDepartment(event) }
								renderOptionLabel={ (department) => `${ department.name } (${ department.code })` }
								isSearchNeeded={ true }
								required={ true }
							/>
						) }

						{ this.preferenceRowContent(
							'Journal',
							'Choisissez le journal de préférence',
							'newspaper-select',
							{ marginTop: '14px' },
							<SelectComponent
								key={ 'newspaper-select' }
								buttonWidth={ 250 }
								selectMenuWidth={ 350 }
								disabled={ (!this.props.client?.options?.department && this.props.client?.options?.newspaperType) }
								listOptions={ this.state.newspapers || [] }
								onSelectedOption={ (event: any) => this.onSelectedNewspaper(event) }
								selectedValue={ this.state.formData.options.newspaper ? this.state.formData.options.newspaper as NewspaperInterface : null }
								renderOptionLabel={ (newspaper: NewspaperInterface) => newspaper ? `${ newspaper.name }` : null }
							/>
						) }

						{ this.preferenceRowContent(
							'Méthode de paiement',
							'Ajuster la méthode de paiement de prédilection',
							'payment-method',
							{ marginTop: '14px' },
							<SelectComponent
								key={ 'payment-method' }
								buttonWidth={ 150 }
								selectMenuWidth={ 220 }
								listOptions={ PaymentMethodEnum.options }
								onSelectedOption={ (event: any) => this.onSelectedPaymentMethod(event) }
								selectedValue={ (this.state.formData.options.paymentMethod) ? PaymentMethodEnum.findByValue(this.state.formData.options.paymentMethod as string) : null }
								renderOptionLabel={ (option) => `${ option.label }` }
							/>
						) }

						{ this.preferenceRowContent(
							'Préférence de Remise',
							'Choisissez la préférence des remises de ce client',
							'client-discount',
							{ marginTop: '14px' },
							<SelectComponent
								buttonWidth={ 180 }
								selectMenuWidth={ 250 }
								listOptions={ ClientDiscountPreferenceEnum.options }
								onSelectedOption={ (event: any) => this.onSelectedDiscountPreference(event) }
								selectedValue={ (this.state.formData.options.discountPreference) ? ClientDiscountPreferenceEnum.findByValue(this.state.formData.options.discountPreference as string) : null }
								renderOptionLabel={ (option) => `${ option.label }` }
							/>
						) }

						{ this.preferenceRowContent(
							'Remise accordée',
							'Vous avez la possibilité d\'accorder une remise à ce client',
							'customer-discount',
							{ marginTop: '14px' },
							<div>
								<Input
									type={ 'number' }
									name={ 'customer-discount' }
									style={ { flex: '0 0 100px' } }
									value={ this.state.formData.options.discountGranted }
									onChange={ (event) =>
										this.setState((prevState: any) => ({
											formData: {
												...prevState.formData,
												options: {
													...prevState.formData.options,
													discountGranted: parseInt(event.target.value)
												}
											}
										}))
									}
								/>
							</div>
						) }

						{ this.preferenceRowContent(
							'Nombre de journaux (Journaux PAPIER)',
							'Pour les journaux (papier), vous avez la possibilité de choisir un nombre de journaux par défault',
							'customer-number-of-copies',
							{ marginTop: '14px' },
							<div>
								<Input
									type={ 'number' }
									name={ 'customer-number-of-copies' }
									style={ { flex: '0 0 100px', width: 'auto' } }
									value={ this.state.formData.options.numberOfCopies }
									onChange={ (event) =>
										this.setState((prevState: any) => ({
											formData: {
												...prevState.formData,
												options: {
													...prevState.formData.options,
													numberOfCopies: parseInt(event.target.value)
												}
											}
										}))
									}
								/>
							</div>
						) }
					</div>
					<div style={ { marginTop: '20px' } }>
						<LineSeparator style={ { marginTop: '20px' } }/>
						<div style={ { ...FontStyle.h4(), marginTop: 10 } }>Note</div>
						<Textarea
							value={ this.state.formData.options.commentary || '' }
							name={ 'commentary' }
							onChange={ (event) =>
								this.setState((prevState: any) => ({
									formData: {
										...prevState.formData,
										options: {
											...prevState.formData.options,
											commentary: event.target.value
										}
									}
								}))
							}
						/>
					</div>
					<div style={ {
						backgroundColor: CssVariableEnum['--color-white'],
						padding: 10,
						borderRadius: 15,
						marginTop: 20
					} }>
						<div style={ { ...FontStyle.h4(), marginTop: 10 } }>Envoi de mails</div>
						<LegalNoticeSendToFormComponent
							selectedClient={ this.props.client }
							sendTo={ this.props.client.options && this.props.client.options.sendTo ? this.props.client.options.sendTo : {} }
							onSelections={ this.onSendToSelected }
						/>
					</div>
				</div>
			</>
		);
	}

	private preferenceRowContent(
		title: string,
		subTitle: string,
		key: any,
		style: CSSProperties,
		preferenceToModify: any
	): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					alignItems: 'flex-end',
					justifyContent: 'space-between',
					cursor: 'default',
					...style
				} }
						 key={ key }
				>
					<div style={ { display: 'flex', flexDirection: 'column', marginRight: '5%' } }>
						<div style={ {
							marginBottom: '2px',
							fontWeight: 600,
							color: CssVariableEnum['--color-grey-900']
						} }>
							{ title }
						</div>
						<div style={ {
							fontSize: '12px',
							lineHeight: '16px',
							color: CssVariableEnum['--color-grey-400']
						} }>
							{ subTitle }
						</div>
					</div>
					{ preferenceToModify }
				</div>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="State methods" defaultstate="collapsed">

	private onChangeSelectClientType(selectedValueOption: any): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				options: {
					...prevState.formData.options,
					type: selectedValueOption.value
				}
			},
		}));
	}

	private onChangeSelectBilledTo(selectedValueOption: BilledToTypeEnum): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				options: {
					...prevState.formData.options,
					billedTo: selectedValueOption.value
				}
			}
		}));
	}

	private onChangeSelectedNewspaperType(selectedValueOption: NewspaperTypeEnum): void
	{
		this.setState((prevState: any) => ({
			isLoadingComponent: true,
			formData: {
				...prevState.formData,
				options: {
					...prevState.formData.options,
					newspaperType: selectedValueOption.value,
					newspaper: null,
				}
			},
		}), () =>
		{
			if (this.state.formData.options.department) {
				this.buildNewspaperList(
					this.state.formData.options.department as DepartmentInterface,
					this.state.formData.options.newspaperType as string
				);
			}

			this.setState({ isLoadingComponent: false });
		});
	}

	private async onSelectedDepartment(department: DepartmentInterface): Promise<void>
	{
		const newspaperType = this.state.formData.options.newspaperType;

		let newspaperTypeValue: string;
		if (newspaperType === null) {
			newspaperTypeValue = NewspaperTypeEnum.WEB.value;
		} else if (typeof newspaperType === 'string') {
			newspaperTypeValue = newspaperType;
		} else {
			newspaperTypeValue = newspaperType.value;
		}

		const newspaperByDepartment = await this.publicService.newspaperByDepartmentAndNewspaperType(
			this.props.client.company.id,
			department.id,
			newspaperTypeValue
		);
		this.setState((prevState: { formData: any }) => ({
			...prevState,
			formData: {
				...prevState.formData,
				options: {
					...prevState.formData.options,
					department: department
				}
			},
			newspapers: newspaperByDepartment,
		}));
	}

	private onSelectedNewspaper(newspaper: NewspaperInterface): void
	{
		this.setState((prevState: { formData: any }) => ({
			formData: {
				...prevState.formData,
				options: {
					...prevState.formData.options,
					newspaper: newspaper
				}
			},
		}));
	}

	private onSelectedPaymentMethod(paymentMethod: PaymentMethodEnum): void
	{
		this.setState((prevState: { formData: any }) => ({
			formData: {
				...prevState.formData,
				options: {
					...prevState.formData.options,
					paymentMethod: paymentMethod.value
				}
			},
		}));
	}

	private onSelectedDiscountPreference(discountPreference: ClientDiscountPreferenceEnum): void
	{
		this.setState((prevState: { formData: any }) => ({
			formData: {
				...prevState.formData,
				options: {
					...prevState.formData.options,
					discountPreference: discountPreference.value
				}
			},
		}));
	}

	private onSendToSelected(selections: any): void
	{
		this.setState((prevState: { formData: any }) => ({
			formData: {
				...prevState.formData,
				options: {
					...prevState.formData.options,
					sendTo: selections
				}
			}
		}));
	}

	//</editor-fold>

	//<editor-fold desc="Build data methods" defaultstate="collapsed">

	private async buildNewspaperList(department: DepartmentInterface, newspaperType: string): Promise<any>
	{
		return await this.publicService.newspaperByDepartmentAndNewspaperType(
			this.props.client.id,
			department.id,
			newspaperType
		);
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private async initOptionsData(): Promise<any>
	{
		const options = this.props.client.options || {};
		let newspaperList = [];
		if (this.props.client?.options?.department && this.props.client?.options?.newspaperType) {
			newspaperList = await this.buildNewspaperList(
				this.props.client?.options?.department,
				this.props.client?.options?.newspaperType.toString()
			);
		}

		this.setState({
			formData: {
				options: {
					...options,
					department: options.department,
					newspaperType: typeof options.newspaperType === 'object'
						? options.newspaperType?.value
						: options.newspaperType || null,
					paymentMethod: options.paymentMethod || null,
					billedTo: options.billedTo || null,
					type: options.type || null,
					discountGranted: options.discountGranted || 0,
					discountPreference: options.discountPreference || null,
					commentary: options.commentary || null,
					numberOfCopies: options.numberOfCopies || 0,
					sendTo: options.sendTo || []
				}
			},
			newspapers: newspaperList,
			isLoadingComponent: false
		}, () => this.setState({ isOptionsUpdated: false }));
	}

	private onUpdateOptions(): void
	{
		this.setState({ isOptionsUpdated: false });
		this.props.onUpdateOptions(this.state.formData, this.props.client.id);
	}

	//</editor-fold>
}
