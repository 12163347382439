import React, { ReactElement } from 'react';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import {
	BsArrowUpLeft,
	BsFloppy,
	BsPencil,
	BsPlusLg,
} from 'react-icons/bs';
import { dateFormat } from '@/Utils/DateUtils';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import CollaboratorShowAdminView from '@/Modules/Collaborator/Admin/View/CollaboratorShowAdminView';
import { NavigateFunction } from 'react-router-dom';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import AdminCreateCollaboratorForm from '@/Modules/Collaborator/Components/Form/AdminCreateCollaboratorForm';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import TagEnum from '@/Modules/App/Components/Atom/Tags/TagEnum';
import Table from '@/Modules/App/Components/Atom/Table/Table';
import TableCol from '@/Modules/App/Components/Atom/Table/TableCol';
import TableRow from '@/Modules/App/Components/Atom/Table/TableRow';
import TableCell from '@/Modules/App/Components/Atom/Table/TableCell';
import { ModalContextType } from '@/Provider/ModalProvider';
import CollaboratorRoleEnum from '@/Enum/CollaboratorRoleEnum';
import AddLogoClientFormComponent from '@/Modules/Client/Components/Form/AddLogoClientFormComponent';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { LuMaximize2, LuPlus, LuTrash2, LuRepeat, LuFileDown } from 'react-icons/lu';
import Swal from 'sweetalert2';
import AdminSwitchCollaboratorOwner from '@/Modules/Collaborator/Components/Form/AdminSwitchCollaboratorOwner';
import AddCustomLogoClientFormComponent from '@/Modules/Client/Components/Form/AddCustomLogoClientFormComponent';
import TableComponent, { TableHeaderListInterface } from '@/Modules/App/Components/Atom/Table/TableComponent';
import { CompanySellsyInterface } from '@/Modules/Client/Interface/CompanySellsyInterface';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import ClientOptionsComponent from '@/Modules/Client/Components/ClientOptionsComponent';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import Skeleton from 'react-loading-skeleton';

interface ComponentProps
{
	selectedClient: ClientInterface,
	selectedTabOffCanvas: number,
	departments: DepartmentInterface[],
	currentItemId: number | null,
	flashMessageContext: FlashMessageContextType,
	authCompanyContext?: AuthCompanyContextType,
	companyId: number,
	navigation: NavigateFunction,
	modalContext: ModalContextType,
	handleDeleted: (userId: number) => void,
	handleUpdated: (updatedUserData: any, userId: number) => void,
	isUpdatedSuccess: boolean,
	fromUrl?: string | null,
	onRefresh: (selectedClientId: number) => Promise<void>,
	isLoading?: boolean,
}

interface ComponentState
{
	isEditMode: boolean,
	isLoading: boolean,
	isEditModeCollaborator: boolean,
	selectedCollaborator: CollaboratorInterface | null,
	selectedStatus: ActiveStatusEnum,
	selectedTabOffCanvas: number,
	formData: any,
	errorMessage: string,
	isOpen: boolean,
	isOpenCollaborator: boolean,
	isOpenLogo: boolean,
	sellsyData: CompanySellsyInterface,
	isSellsyDataLoading: boolean
}

export default class ClientShowComponent extends React.Component
	<ComponentProps, ComponentState>
{
	apiClientService: ApiAdminClientService;
	apiClientSellsyService: ApiAdminSellsyService;

	// <editor-fold desc="Ctr" defaultstate="collapsed">

	constructor(props: any)
	{
		super(props);

		this.state = this.initState();

		// Service
		this.apiClientService = new ApiAdminClientService();
		this.apiClientSellsyService = new ApiAdminSellsyService();

		// Bind
		this.inputSelectActiveStatusContent = this.inputSelectActiveStatusContent.bind(this);
		this.collaboratorsHtmlContent = this.collaboratorsHtmlContent.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleUpdated = this.handleUpdated.bind(this);
		this.onCollaboratorRowClick = this.onCollaboratorRowClick.bind(this);
		this.handleCollaboratorUpdated = this.handleCollaboratorUpdated.bind(this);
		this.handleCollaboratorDeleted = this.handleCollaboratorDeleted.bind(this);
		this.handleClientOptions = this.handleClientOptions.bind(this);
		this.isOpen = this.isOpen.bind(this);
		this.isOpenCollaborator = this.isOpenCollaborator.bind(this);
		this.onEditCollaborator = this.onEditCollaborator.bind(this);
		this.onCreateLogo = this.onCreateLogo.bind(this);
	}

	// </editor-fold>

	render(): ReactElement
	{
		return (
			<>
				{ (this.props.selectedTabOffCanvas === 1) &&
          <div style={ { display: 'flex', position: 'absolute', top: '0px', right: '30px', } }>
						{ (this.state.isEditMode)
							? this.saveEditButtonActionContent()
							: this.editAndDeleteButtonActionContent()
						}
          </div>
				}

				<div>
					{ (this.props.selectedTabOffCanvas === 1) &&
						this.rowDataClientEditContent()
					}
					{ (this.props.selectedTabOffCanvas === 2) &&
						this.collaboratorsHtmlContent()
					}
					{ (this.props.selectedTabOffCanvas === 4) &&
						this.clientOptionHtmlContent()
					}
					{ (this.props.selectedTabOffCanvas === 5) &&
						this.logoHtmlContent()
					}
				</div>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		if (this.props.selectedClient.extSellsyId) {
			this.loadSellsyData();
		}

		if (this.props.selectedClient) {
			this.setState({ isLoading: false });
		}
	}

	componentDidUpdate(prevProps: any): void
	{
		if (
			(!prevProps.isUpdatedSuccess && this.props.isUpdatedSuccess)
			|| (this.props.currentItemId && this.props.currentItemId !== prevProps.currentItemId)) {
			this.setState({ isEditMode: false });
		}

		if(prevProps.selectedClient.collaborators !== this.props.selectedClient.collaborators) {
			this.setState({ isLoading: false });
		}
	}

	private initState(): ComponentState
	{
		return {
			isEditMode: false,
			isLoading: true,
			isEditModeCollaborator: false,
			selectedCollaborator: null,
			selectedStatus: this.props.selectedClient.status,
			selectedTabOffCanvas: this.props.selectedTabOffCanvas,
			errorMessage: '',
			isOpen: false,
			isOpenCollaborator: false,
			isOpenLogo: false,
			sellsyData: {} as CompanySellsyInterface,
			isSellsyDataLoading: true,
			formData: {
				name: this.props.selectedClient.name,
				status: this.props.selectedClient.status,
				siren: this.props.selectedClient.siren,
				apeCode: this.props.selectedClient.apeCode,
				phone: this.props.selectedClient.phone,
				address: {
					number: this.props.selectedClient.address?.number || '',
					street: this.props.selectedClient.address?.street || '',
					additionalData: this.props.selectedClient.address?.additionalData || '',
					zipCode: this.props.selectedClient.address?.zipCode || '',
					city: this.props.selectedClient.address?.city || '',
					country: this.props.selectedClient.address?.country || ''
				},
				options: {
					newspaperType: this.props.selectedClient.options ? this.props.selectedClient.options.newspaperType : null,
					newspaper: this.props.selectedClient.options ? this.props.selectedClient.options.newspaper : null,
					department: this.props.selectedClient.options ? this.props.selectedClient.options.department : null,
					paymentMethod: this.props.selectedClient.options ? this.props.selectedClient.options.paymentMethod : null,
					billedTo: this.props.selectedClient.options ? this.props.selectedClient.options.billedTo : null,
					type: this.props.selectedClient.options ? this.props.selectedClient.options.type : null,
					discountGranted: this.props.selectedClient.options ? this.props.selectedClient.options.discountGranted : null,
					numberOfCopies: this.props.selectedClient.options ? this.props.selectedClient.options.numberOfCopies : null,
					discountPreference: this.props.selectedClient.options ? this.props.selectedClient.options.discountPreference : null
				}
			}
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private rowDataClientEditContent(): ReactElement
	{
		return (
			<>
				<div style={ { marginTop: '30px', display: 'flex', flexDirection: 'column', gap: '15px' } }>
					{ this.companyDetailsHtmlContent() }
					{ this.companyAddressHtmlContent() }
					{ this.companySellsyHtmlContent() }
					{
						(this.state.errorMessage)
						&& <div className="error-message">
							{ this.state.errorMessage }
            </div>
					}
				</div>
			</>
		);
	}

	private lineDetailsHtmlContent(label: string, data: any, renderEdit: ReactElement | null): ReactElement
	{
		return (
			<>
				<div style={ { display: 'grid', gridTemplateColumns: '300px auto', width: '100%', marginBottom: '10px' } }>
					<div style={ {
						color: CssVariableEnum['--color-grey-400']
					} }>
						{ label }
					</div>
					{ (this.state.isEditMode && renderEdit)
						? renderEdit
						: <div style={ {
							fontWeight: 600,
							color: CssVariableEnum['--color-grey-900'],
						} }>
							{ this.props.isLoading
								? <Skeleton width={ 150 } height={ 18 } baseColor={ CssVariableEnum['--color-grey-200'] }/>
								: data
							}
						</div>
					}
				</div>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Tab Client methods" defaultstate="collapsed">

	private companyDetailsHtmlContent(): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					width: '100%',
					flexDirection: 'column',
					backgroundColor: CssVariableEnum['--color-white'],
					borderRadius: '15px',
					padding: '10px 15px'
				} }>
					<div style={ { ...FontStyle.h3(), marginBottom: 15 } }>Détails</div>
					{ this.lineDetailsHtmlContent(
						'Donneur d\'ordre depuis',
						this.props.selectedClient.createdAt ? dateFormat(this.props.selectedClient.createdAt) : 'Date non disponible',
						null
					) }
					{ this.lineDetailsHtmlContent(
						'Nom',
						this.props.selectedClient.name,
						<Input
							type="text"
							width={ '100%' }
							name={ 'company-name' }
							containerDivWidth={ '60%' }
							value={ this.state.formData.name }
							onChange={
								(event) =>
									this.setState((prevState) => ({
										formData: { ...prevState.formData, name: event.target.value }
									}))
							}
						/>
					) }
					{ this.lineDetailsHtmlContent(
						'Siren',
						this.props.selectedClient.siren,
						null
					) }
					{ this.lineDetailsHtmlContent(
						'Ape Code',
						this.props.selectedClient.apeCode,
						null
					) }
					{ this.lineDetailsHtmlContent(
						'Statut',
						<TagEnum value={ this.state.formData.status } enumName={ 'ActiveStatusEnum' }>
							{ this.activeStatus(this.props.selectedClient.status.toString()).label }
						</TagEnum>,
						<>
							<div style={ { display: 'flex', alignItems: 'center' } }>
								<TagEnum value={ this.state.formData.status } enumName={ 'ActiveStatusEnum' }>
									{ this.activeStatus(this.state.formData.status.toString()).label }
								</TagEnum>
								{ this.activeStatus(this.state.formData.status.toString()).label === 'Actif'
									? <Button
										type={ 'default' }
										onClick={ () =>
											this.setState((prevState) => ({
												formData: { ...prevState.formData, status: ActiveStatusEnum.INACTIVE.value }
											})) }
									>
										Rendre Inactif
									</Button>
									: <Button
										type={ 'default' }
										onClick={ () =>
											this.setState((prevState) => ({
												formData: { ...prevState.formData, status: ActiveStatusEnum.ACTIVE.value }
											})) }
									>
										Rendre Actif
									</Button>
								}

							</div>
						</>
					) }
				</div>
			</>
		);
	}

	private companyAddressHtmlContent(): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					width: '100%',
					flexDirection: 'column',
					backgroundColor: CssVariableEnum['--color-white'],
					borderRadius: '15px',
					padding: '10px 15px'
				} }>
					<div style={ { ...FontStyle.h3(), marginBottom: 15 } }>Adresse</div>
					{ this.lineDetailsHtmlContent(
						'Numéro',
						this.props.selectedClient.address?.number,
						<Input
							width={ '100%' }
							type="number"
							name={ 'company-address-number' }
							value={ this.state.formData.address.number || '' }
							onChange={
								(event) =>
									this.setState((prevState) => ({
										formData: {
											...prevState.formData,
											address: {
												...prevState.formData.address,
												number: parseInt(event.target.value)
											}
										}
									}))
							}
						/>
					) }
					{ this.lineDetailsHtmlContent(
						'Rue',
						this.props.selectedClient.address.street,
						<Input
							width={ '100%' }
							type="text"
							name={ 'company-address-street' }
							value={ this.state.formData.address.street }
							onChange={
								(event) =>
									this.setState((prevState) => ({
										formData: {
											...prevState.formData,
											address: {
												...prevState.formData.address,
												street: event.target.value
											}
										}
									}))
							}
						/>
					) }
					{ this.lineDetailsHtmlContent(
						'Complément',
						this.props.selectedClient.address.additionalData,
						<Input
							width={ '100%' }
							type="text"
							name={ 'company-address-additional' }
							value={ this.state.formData.address.additionalData }
							onChange={
								(event) =>
									this.setState((prevState) => ({
										formData: {
											...prevState.formData,
											address: {
												...prevState.formData.address,
												additionalData: event.target.value
											}
										}
									}))
							}
						/>
					) }
					{ this.lineDetailsHtmlContent(
						'Code postal',
						this.props.selectedClient.address.zipCode,
						<Input
							width={ '100%' }
							type="text"
							name={ 'company-address-zipcode' }
							value={ this.state.formData.address.zipCode }
							onChange={
								(event) =>
									this.setState((prevState) => ({
										formData: {
											...prevState.formData,
											address: {
												...prevState.formData.address,
												zipCode: event.target.value
											}
										}
									}))
							}
						/>
					) }
					{ this.lineDetailsHtmlContent(
						'Ville',
						this.props.selectedClient.address.city,
						<Input
							width={ '100%' }
							type="text"
							name={ 'company-address-zipcode' }
							value={ this.state.formData.address.city }
							onChange={
								(event) =>
									this.setState((prevState) => ({
										formData: {
											...prevState.formData,
											address: {
												...prevState.formData.address,
												city: event.target.value
											}
										}
									}))
							}
						/>
					) }
					{ this.lineDetailsHtmlContent(
						'Pays',
						this.props.selectedClient.address.country,
						null
					) }
				</div>
			</>
		);
	}

	private companySellsyHtmlContent(): ReactElement
	{
		return (
			<>
				<div style={ {
					display: 'flex',
					width: '100%',
					flexDirection: 'column',
					backgroundColor: CssVariableEnum['--color-purple-100'],
					border: `1px solid ${ CssVariableEnum['--color-purple-300'] }`,
					borderRadius: '15px',
					padding: '10px 15px'
				} }>
					<div style={ { ...FontStyle.h3(), marginBottom: 15, color: CssVariableEnum['--color-purple-500'] } }>
						Sellsy Détails
					</div>
					{ (this.state.isSellsyDataLoading)
						? <LoaderComponent variant={ 'secondary' }/>
						: <>
							{ this.lineDetailsHtmlContent(
								'Id Sellsy',
								this.state.sellsyData?.id || '-',
								null
							) }
							{ this.lineDetailsHtmlContent(
								'Nom',
								this.state.sellsyData?.name || '-',
								null
							) }
							{ this.lineDetailsHtmlContent(
								'Code auxiliaire',
								this.state.sellsyData?.auxiliary_code
									? <span style={ { color: CssVariableEnum['--color-grey-500'] } }>#{ this.state.sellsyData.auxiliary_code }</span>
									: '-',
								null
							) }
							{ this.lineDetailsHtmlContent(
								'Email',
								this.state.sellsyData?.email || '-',
								null
							) }
							{ this.lineDetailsHtmlContent(
								'Numéro de téléphone',
								this.state.sellsyData?.phone_number || '-',
								null
							) }
							<hr/>
							{ this.lineDetailsHtmlContent(
								'Adresse de Facturation et de livraison',
								this.state.sellsyData?._embed?.delivery_address?.address_line_1 || '-',
								null
							) }
							{ this.lineDetailsHtmlContent(
								'Ville',
								this.state.sellsyData?._embed?.delivery_address?.city || '-',
								null
							) }
							{ this.lineDetailsHtmlContent(
								'Code postal',
								this.state.sellsyData?._embed?.delivery_address?.postal_code || '-',
								null
							) }
						</>
					}
				</div>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Tab Collaborator methods" defaultstate="collapsed">

	private collaboratorsHtmlContent(): ReactElement
	{
		return (
			<>
				<div style={ { marginTop: '20px' } }>
					<div style={ {
						display: 'flex',
						width: '100%',
						justifyContent: 'flex-end',
						alignItems: 'center',
						height: '50px'
					} }>
						<Button
							type={ 'default-dark' }
							onClick={ (event?: any) => this.switchCollaboratorOwner(event) }
							disabled={ this.props.selectedClient?.collaborators.length < 2 }
							iconLeft={ <LuRepeat/> }
						>
							Changer de propriétaire
						</Button>
						<Button
							type={ 'default-blue' }
							onClick={ (event?: any) => this.inviteNewCollaborator(event) }
							iconLeft={ <BsPlusLg/> }
						>
							Ajouter
						</Button>
					</div>
					{ this.props.selectedClient.collaborators.length > 0 &&
            <TableComponent
              paginateList={ this.props.selectedClient.collaborators }
              tableHeaderList={ this.buildTableCollaborator() }
              isLoading={ this.state.isLoading }
              params={ () => null }
              onClickRow={ this.onCollaboratorRowClick }
              notFoundValuesText={ 'Ce collaborateur n\'a créé aucune annonce légale' }
              onRefreshList={ this.refreshList }
              isStickyHeader={ true }
              isResizeColumns={ true }
            />
					}
				</div>
			</>
		);
	}

	private async refreshList(): Promise<void>
	{
		await this.props.onRefresh(this.props.selectedClient.id);
	}

	private buildTableCollaborator(): TableHeaderListInterface[]
	{
		return [
			{
				columnTitle: 'Collaborateur',
				orderTag: 'collaborator',
				minWidth: 100,
				width: 250,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'user',
				dataTag: ['user.firstname', 'user.lastname', 'email']
			},
			{
				columnTitle: 'Sellsy',
				orderTag: null,
				minWidth: 100,
				width: 230,
				justifyContent: 'center',
				fontWeight: 400,
				type: 'sellsy',
				dataTag: ['extSellsyId']
			},
			{
				columnTitle: 'Autorisation',
				orderTag: 'role',
				minWidth: 100,
				width: 150,
				justifyContent: 'center',
				fontWeight: 400,
				type: 'tag',
				dataTag: { label: 'role', enumClass: CollaboratorRoleEnum }
			},
			{
				columnTitle: 'Status',
				orderTag: 'status',
				minWidth: 100,
				width: 100,
				justifyContent: 'center',
				fontWeight: 400,
				type: 'tag',
				dataTag: { label: 'status', enumClass: ActiveStatusEnum }
			},
		];
	}

	private onCollaboratorRowClick(event: any, collaborator: CollaboratorInterface)
	{
		event.stopPropagation();

		this.setState({ selectedCollaborator: collaborator, isOpenCollaborator: true },
			() =>
				this.props.modalContext.content(
					null,
					<CollaboratorShowAdminView
						authCompanyContext={ this.props.authCompanyContext as AuthCompanyContextType }
						selectedCollaborator={ this.state.selectedCollaborator as CollaboratorInterface }
						selectedClient={ this.props.selectedClient }
						modalContext={ this.props.modalContext }
						flashMessageContext={ this.props.flashMessageContext }
						navigation={ this.props.navigation }
						handleUpdated={ this.handleCollaboratorUpdated }
						handleDeleted={ this.handleCollaboratorDeleted }
						currentItemId={ this.props.currentItemId }
						isOpen={ this.isOpenCollaborator }
					/>
				)
		);
	}

	private switchCollaboratorOwner(event: any): void
	{
		event.stopPropagation();

		const owner: any | undefined = this.props.selectedClient.collaborators.find(
			(collaborator: any) => collaborator.role === CollaboratorRoleEnum.ROLE_OWNER.value
		);

		if (!owner) {
			this.props.flashMessageContext.flashMessage(
				'Modification impossible',
				'le collaborateur [propriétaire], n\'a pas été trouvé',
				'error'
			);

			return;
		}

		this.props.modalContext.actions([]);
		this.props.modalContext.content(
			'Changer de propriétaire',
			<AdminSwitchCollaboratorOwner
				client={ this.props.selectedClient }
				collaboratorOwner={ owner }
				onSwitchCollaboratorValidate={ () => this.onSwitchOwnerCollaborator() }
				modalContext={ this.props.modalContext }
				flashMessageContext={ this.props.flashMessageContext }
			/>
		);
	}

	private inviteNewCollaborator(event: any): void
	{
		event.stopPropagation();

		this.props.modalContext.actions([]);
		this.props.modalContext.content(
			'Ajouter un collaborateur',
			<AdminCreateCollaboratorForm
				client={ this.props.selectedClient }
				authCompanyContext={ this.props.authCompanyContext as AuthCompanyContextType }
				companyId={ this.props.companyId }
				onRefresh={ this.handleRefreshCollaborator.bind(this) }
				modalContext={ this.props.modalContext }
				flashMessageContext={ this.props.flashMessageContext }
			/>
		);
	}

	private clientOptionHtmlContent(): ReactElement
	{
		return (
			<>
				<ClientOptionsComponent
					key={ this.props.currentItemId }
					client={ this.props.selectedClient }
					departments={ this.props.departments }
					onUpdateOptions={ this.handleClientOptions }
				/>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Tab Logo methods" defaultstate="collapsed">

	private logoHtmlContent(): ReactElement
	{
		return (
			<>
				<div className="d-flex justify-content-between mt-4">
					<Button
						type="closed-dark"
						iconLeft={ <LuPlus/> }
						onClick={ (event: any) => this.onClickAddLogo(event, true) }
					>
						Créer un logo personnalisé
					</Button>
				</div>
				{ this.tableLogoHtmlContent() }
			</>
		);
	}

	private tableLogoHtmlContent(): ReactElement
	{
		if (this.state.isLoading) {
			return (
				<Skeleton
					width={ 200 }
					height={ 25 }
					count={ this.props.selectedClient.logos.length }
					style={ { marginBottom: 10 } }
				/>
			);
		} else {
			return (
				<>
					<div style={ {
						backgroundColor: CssVariableEnum['--color-white'],
						marginTop: 20,
						borderRadius: 12,
						padding: 10,
					} }>
						<Table>
							{ this.props.selectedClient.logos.map((logo: any) => (
								<TableRow
									key={ logo.id }
									borderBottom={ Boolean(this.props.selectedClient.logos.length > 1) }
								>
									<TableCol columnSize={ 4 }>
										<TableCell>
											{ logo.name }
										</TableCell>
									</TableCol>
									<TableCol columnSize={ 8 }>
										<TableCell>
											<div className="d-flex justify-content-center">
												<img style={ { width: '20%' } } src={ logo.logoUrl } alt={ 'logo-' + logo.name }/>
											</div>
										</TableCell>
									</TableCol>
									<div style={ {
										minWidth: '10%',
										height: '100%',
									} }>
										<TableCell>
											<div style={ { display: 'flex', justifyContent: 'space-between' } }>
												<Button
													type={ 'danger' }
													iconLeft={ <LuTrash2/> }
													onClick={ () => this.onDeleteLogo(logo) }
												/>
												<Button
													type={ 'inline-default' }
													iconLeft={ <LuMaximize2/> }
													onClick={ (event: any) => this.onLogoRowClick(event, logo) }
												/>
											</div>
										</TableCell>
									</div>
								</TableRow>
							)) }
						</Table>
					</div>
				</>
			);
		}
	}

	private onClickAddLogo(event: any, isCustom: boolean = false): void
	{
		event.stopPropagation();

		this.setState({ isOpenLogo: true }, () =>
		{
			if (isCustom) {
				event.stopPropagation();
				this.props.modalContext.content(
					'Ajouter un logo',
					<AddCustomLogoClientFormComponent
						client={ this.props.selectedClient }
						modalContext={ this.props.modalContext }
						flashMessageContext={ this.props.flashMessageContext }
						onCreateLogo={ this.onCreateLogo }
					/>
				);
			} else {
				event.stopPropagation();
				this.props.modalContext.content(
					null,
					<AddLogoClientFormComponent
						client={ this.props.selectedClient }
						modalContext={ this.props.modalContext }
						flashMessageContext={ this.props.flashMessageContext }
						onCreateLogo={ this.onCreateLogo }
					/>
				);
			}
		});
	}

	private onLogoRowClick(event: any, logo: any): void
	{
		event.stopPropagation();

		this.props.modalContext.content(
			logo.name,
			<div style={ {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				marginBottom: 25
			} }>
				<div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
					<img
						style={ { width: 288, marginTop: '10px', padding: '10px', border: '1px solid black', marginBottom: 20 } }
						 src={ logo.logoUrl }
						 alt={ 'logo-' + logo.name }
					/>
				</div>
				<Button
					label={ 'Télécharger' }
					type={ 'default-blue' }
					iconLeft={ <LuFileDown/> }
					onClick={ () => this.downloadImage(logo)
					}/>
			</div>
		);
		this.props.modalContext.actions([]);
	}

	private downloadImage(logo: any): void
	{
		const link = document.createElement('a');
		link.href = logo.logoUrl;

		// Utiliser le nom du logo spécifié par l'utilisateur comme nom de fichier
		link.download = logo.name;

		// Append, click, and remove link to trigger the download
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	//</editor-fold>

	//<editor-fold desc="Sellsy methods" defaultstate="collapsed">

	private async loadSellsyData(): Promise<void>
	{
		const sellsyData = await this.apiClientSellsyService.getCompany(
			this.props.companyId,
			parseInt(this.props.selectedClient.extSellsyId)
		);

		this.setState({ sellsyData, isSellsyDataLoading: false });
	}

	//</editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	private handleRefreshCollaborator(selectedClientId: number): void
	{
		this.setState({ isLoading: true });
		this.props.onRefresh(selectedClientId);
	}

	private onEditCollaborator(isEditMode: boolean): void
	{
		this.setState({
			isEditModeCollaborator: isEditMode,
		});
	}

	private saveEditButtonActionContent(): ReactElement
	{
		return (
			<>
				<Button
					type={ 'default' }
					onClick={ () => this.setState({ isEditMode: false }) }
				>
					Annuler
				</Button>
				<Button
					type={ 'default-blue' }
					iconLeft={ <BsFloppy/> }
					onClick={ this.handleUpdated.bind(this) }
				>
					Sauvegarder
				</Button>
			</>
		);
	}

	private editAndDeleteButtonActionContent(): ReactElement
	{
		return (
			<>
				{ this.props.fromUrl &&
          <Button
            type={ 'default' }
            iconLeft={ <BsArrowUpLeft/> }
            onClick={ () => (this.props.fromUrl) ? this.props.navigation(this.props.fromUrl.toString()) : null }
          >
            Retour
          </Button>
				}
				<Button
					type={ 'default' }
					iconLeft={ <BsPencil/> }
					onClick={ () => this.setState({ isEditMode: true }) }
				>
					Editer
				</Button>
				<Button
					type={ 'danger' }
					iconLeft={ <LuTrash2/> }
					onClick={ () => this.handleDelete() }
				/>

			</>
		);
	}

	private isOpenCollaborator(isOpen: boolean): void
	{
		this.setState({ isOpenCollaborator: isOpen });
	}

	private activeStatus(activeStatus: string): any
	{
		return ActiveStatusEnum.roleOptions.find(
			role => role.value === activeStatus
		);
	}

	private inputSelectActiveStatusContent(): ReactElement
	{
		return (
			<>
				<SelectComponent
					onSelectedOption={ this.activeStatus(this.props.selectedClient.status.toString()) }
					listOptions={ ActiveStatusEnum.roleOptions }
					renderOptionLabel={ option => option.label }
				/>
			</>
		);
	}

	private handleDelete(): void
	{
		this.props.handleDeleted(this.props.selectedClient.id);
	}

	private handleUpdated(): void
	{
		const updatedClientData = {
			name: this.state.formData.name,
			siren: this.state.formData.siren,
			apeCode: this.state.formData.apeCode,
			status: this.state.formData.status,
			address: {
				...this.state.formData.address
			}
		};

		this.props.handleUpdated(updatedClientData, this.props.selectedClient.id);
	};

	private handleCollaboratorUpdated(updatedCollaborator: CollaboratorInterface): void
	{
		this.setState({ selectedCollaborator: updatedCollaborator });
		this.props.handleUpdated(
			{},
			this.props.selectedClient.id
		);
	}

	private handleCollaboratorDeleted(collaboratorDeleted: CollaboratorInterface): void
	{
		this.setState({ selectedCollaborator: collaboratorDeleted });
		// We used updated for deleted to refresh the deleted Collaborator WARNING ! DON'T CHANGE FOR HANDLE DELETED !
		this.props.handleUpdated({}, this.props.selectedClient.id);
	}

	private handleClientOptions(formDataOptions: any, clientId: number): void
	{
		const { options } = formDataOptions;
		const { createdAt, updatedAt, newspaperType, department, newspaper, ...restOptions } = options;

		// Convert newspaperType to string if it's an object
		const cleanedNewspaperType = (typeof newspaperType === 'object' && newspaperType !== null) ? newspaperType.value : newspaperType;

		// Extract departmentId from department object
		const departmentId = department && typeof department === 'object' ? department.id : department;

		// Extract newspaperId from newspaper object
		const newspaperId = newspaper && typeof newspaper === 'object' ? newspaper.id : newspaper;

		const filteredOptions = {
			...restOptions,
			newspaperType: cleanedNewspaperType,
			departmentId: departmentId,
			newspaperId: newspaperId
		};

		const cleanedFormDataOptions = {
			...formDataOptions,
			options: filteredOptions
		};

		this.props.handleUpdated(cleanedFormDataOptions, clientId);
	}

	private async onCreateLogo(logoData: any): Promise<void>
	{
		this.setState({ isLoading: true });
		const response = await this.apiClientService.addLogo(logoData, this.props.selectedClient.id);

		if (!response.errorMessage) {
			console.log(response.errorMessage);
		}

		if (response) {
			this.setState({ isLoading: false });
			this.props.handleUpdated(
				{},
				this.props.selectedClient.id
			);
		}
	}

	private async onDeleteLogo(logo: any): Promise<void>
	{
		this.setState({ isLoading: true });
		const result = await Swal.fire({
			title: 'Voulez vous vraiment supprimer ce logo: ' + logo.name + ' ?',
			text: 'Si vous supprimez ce logo, cette action est définitive',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Oui, supprimez-le!'
		});

		if (result.isConfirmed) {
			Swal.fire(
				'Supprimé!',
				'Le logo a bien été supprimée.',
			);

			const response = await this.apiClientService.deleteLogo(this.props.selectedClient.id, logo.id);

			if (!response.errorMessage) {
				console.log(response.errorMessage);
			}

			if (response) {
				this.setState({ isLoading: false });
				this.props.handleUpdated(
					{},
					this.props.selectedClient.id
				);
			}
		}
	}

	private isOpen(isOpen: boolean): void
	{
		this.setState({ isOpen: isOpen });
	}

	private async onSwitchOwnerCollaborator(): Promise<void>
	{
		this.props.modalContext.isOpen(false);
		this.props.flashMessageContext.flashMessage(
			'Modification réussie',
			'Changement de propriétaire, effectué avec succès',
			'success'
		);
		this.props.onRefresh(this.props.selectedClient.id);
	}

	//</editor-fold>
}